
<template>
  <div>

    <div class="block w-full text-right mr-2 mb-8">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
    </div>
    <vx-card id="ecommerce-checkout-demo" class="bg-h-primary-light">
      <!-- ServerTpe: Default -->
    <!-- <div v-if=" serverType == 'default' || serverLocation == 'default'">
      <div class="flex gap-3 flex-col text-lg text-center">
        لطفا به داشبورد برگردید و نوع سرور خود را با کلیک بر روی دکمه سرویس جدید مشخص نمایید.
      </div>
    </div> -->
    <!-- ServerType: Linux - IRAN -->
    <div class="my-5 bg-h-primary p-5 rounded-lg flex w-full" >

      <b-form @submit.stop.prevent="addInstance()" class="w-full">

        <div class="md:flex justify-between gap-4 mb-10" style="direction: ltr;">
          <div class="w-full">
            <span class=" block">عنوان کلاستر</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25" placeholder="عنوان کلاستر" v-model="form.group_title"  required>
          </div>


        </div>

        <div class="md:flex justify-between gap-4 mb-10" style="direction: ltr;">
          <div class="w-full ">
            <span class=" block">تعداد ورکر</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25" placeholder="تعداد ورکرهای کلاستر" v-model="form.total_worker"  required>
          </div>


        </div>

        <div v-if="isSshFromActive" class="mt-10 w-full relative bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border" title="انتخاب کلید ssh">
          <span class="text-sm md:text-base">انتخاب کلید ssh برای افزودن به سرور </span>
          <vs-select
            v-if="sshKeyList.length > 0"
             v-model="sshKeyId"
              autocomplete
              class="selectExample w-full mt-5"
              label="عنوان کلید  : "
            >
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.ssh_key_title"
                v-for="(item, index) in sshKeyList"
              />
            </vs-select>
            <b-button v-else target="_blank" to="/apps/addKey" class="mt-4 w-full border-0 bg-h-secondary text-white">
              افزودن کلید ssh</b-button>
          </div>

        <b-row  class="block md:flex mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border w-full m-auto">

      <!-- <b-col cols="12" md="4" lg="2" class="mt-3 text-center md:text-right">
        <b-form-group label="">
          <b-form-static class="text-xl lg:text-3xl ">
            {{ prices | money }}
            <span class="text-sm"> تومان/ساعت</span>
          </b-form-static>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="2" class="text-center md:text-right">
        <b-form-group>
          <b-form-static class="text-xl lg:text-3xl">
            {{ monthPrice | money }}
            <span class="text-xs md:text-sm"> تومان/ماهیانه</span><br>
            <p class="text-xs md:text-sm text-danger text-left">   هزینه ترافیک مصرفی در دیتاسنترهای ایران جداگانه محاسبه می‌شود.
              <a class="text-success" href="https://www.haio.ir/traffic-price/" target="_blank">توضیحات تکمیلی</a>

            </p>
          </b-form-static>
        </b-form-group>
      </b-col> -->
      <b-col cols="12" md="6" lg="2" class="border-0 border-t md:border-t-0 md:border-l border-dashed border-white border-opacity-25 pl-0 pt-3 md:pt-0">
        <b-form-group >
          <div class="text-center">

            <b-form-checkbox v-model="isSshFromActive"  class="rounded-pill m-auto">استفاده از کلید SSH <span class="text-muted">(اختیاری)</span></b-form-checkbox>            </div>

          </b-form-group>
        </b-col>
        <b-col cols="12" lg="26" md="6" class="text-center md:text-right flex justify-end gap-1">
          <!-- <b-button @click="howToCal = !howToCal" class=" ">نحوه محاسبه</b-button> -->
          <b-button type="submit" variant="primary" class="block text-base md:text-lg px-5 md:px-10 py-3">ساخت کلاستر</b-button>
        </b-col>
    </b-row>
    <b-row v-if="(howToCal)"  class="block mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border w-full m-auto">
                  <div class="mt-4">
              <div class="w-full">
                <table class="table table-striped table-dark table-hover w-full rounded ">
                  <thead class="rounded">
                    <tr class="rounded text-center">
                      <th scope="col">منابع</th>
                      <th scope="col">مقدار انتخابی</th>
                      <th scope="col">قیمت هر واحد</th>
                      <th scope="col">قابل پرداخت (مقدار x قیمت)</th>
                    </tr>
                  </thead>
                  <tbody class="text-center ">
                    <tr scope="row">
                      <td>RAM</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  ram  }}GB</td>
                      <td>{{ ramPrice }} تومان</td>
                      <td>{{ ram * ramPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>CPU</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  cpu  }}Core</td>
                      <td>{{ cpuPrice }} تومان</td>
                      <td>{{ cpu * cpuPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>HARD</td>
                      <td style="font-family: Arial, Helvetica, sans-serif">{{  hard  }}GB</td>
                      <td>{{ hardPrice }} تومان</td>
                      <td>{{ hard * hardPrice | money}} تومان/ساعت</td>
                      </tr>
                    <tr  scope="row">
                      <td>IP</td>
                      <td>1 عدد</td>
                      <td>{{ ipPrice }} تومان</td>
                      <td>{{ 1 * ipPrice }} تومان/ساعت</td>
                      </tr>

                    <tr scope="row" >
                      <td colspan="3" class="text-center">جمع کل مبلغ قابل پرداخت در هر ساعت:</td>
                      <td class="bg-h-secondary text-white rounded-lg ">{{ ram * ramPrice +  cpu * cpuPrice + hard * hardPrice + ipPrice | money }} تومان/ساعت</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
            </b-row>

      </b-form>
    </div>

  </vx-card>
</div>
</template>

<script>

export default {
  data () {
    return {
      form: {
        serverName: '',
        hostName: '',
        selectedlocation: '',
        selectedDatacenter:'',
        selectedOS:'',
        selectedAPP: '',
        selectedVersion:'',
        resource:0

      },
      howToCal: false,
      locations: {},
      location:'',
      imageList: {
        OS: '',
        APP: '',
        ISO: ''
      },
      // datacenter: '',
      datacenters: [],
      datacenter_id:'',
      ram:'',
      cpu:'',
      hard:'',
      isSshFromActive: false,
      sshKeyId  : '',
      sshKeyList : [],
      versions: [],
      os:'',
      hw_req:[],
      cpuPrice:'',
      hardPrice:'',
      ramPrice:'',
      ipPrice:''

    }
  },
  computed: {
    serverType () {
      return this.$route.params.serverType || 'default'
    },
    serverLocation () {
      return this.$route.params.location || 'default'
    },
    monthPrice () {
      return parseInt(this.prices) * 24 * 30
    },
    prices () {
      return (
        parseInt(this.cpuPrice * this.cpu) +
        parseInt(this.hardPrice * this.hard) +
        parseInt(this.ramPrice * this.ram) +
        parseInt(this.ipPrice)

      )
    },
    validateIP () {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.vpcSubnet)) {
        return true
      } else {

        return false
      }
    }
  },
  watch: {
    'form.selectedlocation': 'resetDatacenter',
    isSshFromActive () {
      this.getSSHKeyList()
    }

  },
  methods: {
    resetDatacenter () {
      // alert('locarnio cahnged')
      // alert(this.form.selectedDatacenter)
      this.form.selectedDatacenter = ''
    },
    isLetter (e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Z-a-z0-9]+$/.test(char)) return true // Match with regex
      else this.sweetAlert(['خطا در نام سرور', 'لطفا بدون فاصله و فقط لاتین تایپ کنید', 'باشه', 'false']) // If not match, don't add to input text
    },
    sweetAlert (txt) {
      if (txt[3] !== 'false') {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        }).then(() => {
          this.$router.push(txt[3])
        })
      } else {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        })
      }
    },
    onSubmit (event) {
      event.preventDefault()
      // alert(JSON.stringify(this.form))
    },
    getLocations () {
      this.$vs.loading()
      this.form.selectedOS = ''
      this.form.selectedAPP = ''
      this.form.selectedlocation = ''
      this.form.selectedVersion = ''
      this.$http
        .get('/cloud/location')
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params
          const parsedobj = JSON.stringify(data.data)
          const p = JSON.parse(parsedobj)
          this.locations = p

        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message
          })
        })
    },
    getDatacenters (id) {
      this.form.selectedOS = ''
      this.form.selectedAPP = ''
      this.form.selectedVersion = ''
      // alert(id)
      // dirty Code!
      this.datacenters = this.locations.find((car) => car.id === parseInt(id)).datacenters
      // alert(this.datacenters[0].title)
      this.setDatacenter(this.datacenters)
    },
    setDatacenter (value) {
      this.datacenter_id = value.datacenter_id
      // this.datacenter_title = value.datacenter_location_name
      if (this.form.selectedDatacenter > 0 && this.datacenter_id !== '') {
        this.getImageList(this.datacenter_id)
      }
    },
    getImageList (id) {
      if (id !== '') {
        this.form.selectedVersion = ''
        this.form.selectedOS = ''
        this.form.selectedAPP = ''
        // this.imageList = {}
        this.$vs.loading()
        this.$http
          .get(`/cloud/images?type=0&datacenter_id=${id}&proxy=1`)
          .then((res) => {
            this.$vs.loading.close()
            const data = res.data.params
            // console.log('data as data', data)
            const parsedobj = JSON.stringify(data.data)
            const p = JSON.parse(parsedobj)
            this.imageList = p
            this.form.selectedOS = ''
            this.form.selectedAPP = ''
            this.selectedVersion = ''

          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              color: 'danger',
              title: err.response.data.message
            })
          })
      }
    },
    // getImageList (id) {
    //   this.form.selectedVersion = ''

    //   this.$vs.loading()
    //   this.$http
    //     .get(`/cloud/image/template?type=0&datacenter_id=${id}`)
    //     .then((res) => {
    //       this.$vs.loading.close()
    //       const data = res.data.params
    //       // console.log('data as data', data)
    //       const parsedobj = JSON.stringify(data.data)
    //       const p = JSON.parse(parsedobj)
    //       this.imageList = p
    //       this.form.selectedOS = ''

    //       this.selectedVersion = ''
    //     })
    //     .catch((err) => {
    //       this.$vs.loading.close()
    //       this.$vs.notify({
    //         color: 'danger',
    //         title: err.response.data.message
    //       })
    //     })
    // },
    getVersions (type) {
      // os-app-radio
      // document.getElementsByClassName('os-app-radio').checked = false
      this.form.selectedVersion = ''
      switch (type) {
      case 'OS':
        this.form.selectedAPP = ''
        this.versions = this.imageList.OS.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')

        break

      case 'APP':
        this.form.selectedOS = ''
        this.versions = this.imageList.APP.find((version) => version.id === parseInt(this.form.selectedAPP)).versions.split(',')

        break

      default:
        break

      }
      this.getPrice(this.form.selectedDatacenter)
    },
    // getResources (selectedPack) {
    //   const pack = selectedPack

    //   this.os = this.imageList.find((Os) => Os.id === parseInt(this.form.selectedOS))
    //   this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedOS))

    //   if (this.os.title === 'Windows') {
    //     this.ram = this.hw_req.min_ram
    //     this.cpu = this.hw_req.min_vcpu
    //     this.hard = this.hw_req.min_hard
    //     switch (pack) {
    //     case '1':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '40'
    //       break
    //     case '2':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '50'
    //       break
    //     case '3':
    //       this.ram = '2'
    //       this.cpu = '3'
    //       this.hard = '50'
    //       break
    //     case '4':
    //       this.ram = '3'
    //       this.cpu = '3'
    //       this.hard = '55'
    //       break
    //     case '5':
    //       this.ram = '3'
    //       this.cpu = '3'
    //       this.hard = '60'
    //       break
    //     case '6':
    //       this.ram = '3'
    //       this.cpu = '4'
    //       this.hard = '60'
    //       break
    //     case '7':
    //       this.ram = '4'
    //       this.cpu = '4'
    //       this.hard = '80'
    //       break
    //     case '8':
    //       this.ram = '5'
    //       this.cpu = '4'
    //       this.hard = '100'
    //       break
    //     case '9':
    //       this.ram = '6'
    //       this.cpu = '4'
    //       this.hard = '110'
    //       break
    //     case '10':
    //       this.ram = '6'
    //       this.cpu = '6'
    //       this.hard = '130'
    //       break

    //     default:
    //       break
    //     }

    //   } else {
    //     this.ram = this.hw_req.min_ram
    //     this.cpu = this.hw_req.min_vcpu
    //     this.hard = this.hw_req.min_hard
    //     switch (pack) {
    //     case '1':
    //       this.ram = '2'
    //       this.cpu = '2'
    //       this.hard = '20'
    //       break

    //     default:
    //       break
    //     }
    //   }
    //   this.versions = this.imageList.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')
    //   this.getPrice(this.form.selectedDatacenter)
    // },
    getResources () {
      //const pack = selectedPack
      this.os = ''

      if (this.form.selectedOS !== '') {
        this.os = this.imageList.OS.find((Os) => Os.id === parseInt(this.form.selectedOS))
        // this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedOS))
        // if (this.form.selectedOS !== '') {
        this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.version === this.form.selectedVersion)

        this.ram = this.hw_req.min_ram
        this.cpu = this.hw_req.min_vcpu
        this.hard = this.hw_req.min_hard
        // }


        this.versions = this.imageList.OS.find((version) => version.id === parseInt(this.form.selectedOS)).versions.split(',')
      }

      if (this.form.selectedAPP !== '') {
        this.os = this.imageList.APP.find((Os) => Os.id === parseInt(this.form.selectedAPP))
        // this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.template_id === parseInt(this.form.selectedVersion))
        this.hw_req = this.os.hw_requirement.find((versionOfOs) => versionOfOs.version === this.form.selectedVersion)

        this.ram = this.hw_req.min_ram
        this.cpu = this.hw_req.min_vcpu
        this.hard = this.hw_req.min_hard
      }

      this.versions = this.imageList.APP.find((version) => version.id === parseInt(this.form.selectedAPP)).versions.split(',')

      this.getPrice(this.form.selectedDatacenter)
    },
    async addInstance () {

      const insData = {
        ssh_key_id : this.sshKeyId,
        group_title: this.form.group_title,
        total_worker: this.form.total_worker,
        datacenter_id: 3
      }

      this.$vs.loading()
      try {
        // eslint-disable-next-line no-unused-vars
        await this.$http.post('/cloud/docker/cluster', insData)
        this.sweetAlert(['سرور ساخته شد', 'اطلاعات ورود به سرور به ایمیل شما ارسال خواهد شد', 'لیست سرورها', '/docker/list'])

      } catch (err) {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      }
      this.$vs.loading.close()


    },
    getPrice (id) {
      const data = { cpu: 1, ram: 1, storage: 5 }
      this.$http
        .get(`/cloud/plan?datacenter_id=${id}`, data)
        .then((res) => {
          const a = res.data.params.data[0]
          this.price = a

          this.cpuPrice = parseInt(a.vcpu_price)
          this.hardPrice = parseInt(a.hard_price)
          this.ramPrice = parseInt(a.ram_price)
          this.ipPrice = parseInt(0)

        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: ' خطا',
            text: err.response.data.message
          })
        })
    },

    updateItemQuantity (event, index) {
      const itemIndex = Math.abs(index + 1 - this.cartItems.length)
      this.$store.dispatch('eCommerce/updateItemQuantity', {
        quantity: event,
        index: itemIndex
      })
    },
    getSSHKeyList () {
      this.$http.get('/ssh/key/list').then((res) => {
        this.sshKeyList = res.data.params.data
      }).catch(() => {

      })
    }

  },

  components: {

  },
  mounted () {


  },
  created () {
    // this.getLocations()
  }
}
</script>
<style>
.vs-slider--circle {border-radius: 50% }
[dir=rtl] .text-circle-slider.vs-slider--circle-text{    font-family: sans-serif;
    font-size: 16px;
    direction: ltr !important;
}
.theme-dark .con-vs-slider .vs-slider {
    background: #ffffffa1;
    height: 6px;
    border-radius: 50px;
}
.nav-item a {
  color: #696b6d !important;
}
</style>
